﻿@media (max-width: 600px) {
  .sv-root-modern {
    .sv-container-modern {
      .sv-body {
        .sv-page {
          .sv-row {
            .sv-question,
            .sv-row__question {
              display: block;
              width: 100% !important;
              .sv-question__header--location--left {
                float: none;
              }
              .sv-selectbase__item--inline,
              .sv-imagepicker__item--inline {
                display: block;
              }
              table.sv-table {
                display: block;
                thead {
                  display: none;
                }
                td.sv-table__cell--choice {
                  text-align: initial;
                }
                tbody,
                tr,
                td {
                  display: block;
                }
              }
              table.sv-matrixdropdown,
              table.sv-matrixdynamic {
                td {
                  &:before {
                    content: attr(data-responsive-title);
                  }
                }
              }
              table.sv-matrix-root {
                td {
                  label.sv-matrix__label {
                    display: inline;
                  }
                  &:after {
                    content: attr(data-responsive-title);
                  }
                }
                .sv-matrix__cell {
                  text-align: initial;
                }
              }
            }
          }
        }
      }
    }
  }
}
